@use 'customStyles.scss';

.search-layout {
  display: grid;
  grid-template-columns: 50px 1fr;
  .vertical-align {
    writing-mode: vertical-rl;
    transform: scale(-1);
    transform-origin: center bottom;
    .C-Button {
      height: auto;
    }
  }
  .vertical-align-icon {
    transform: rotate(90deg);
  }
}
.search-layout-expanded {
  grid-template-columns: 332px 1fr !important;
}
.search-rail {
  background: var(--canvas-background-color--base);
  bottom: 0;
  display: flex;
  flex-direction: column;
  transition:
    width 0.3s ease,
    right 0.3s ease,
    left 0.3s ease;
  width: 100%;
  border-radius: 8px 8px 8px 0;
  .section {
    height: 100%;
    min-height: calc(100% - 120px);
    display:table;
    #quick-search, #all-filters, #applied-filters {
      display:table-row;
    }
    #all-filters {
      height:45vh;
      min-height:45vh;
      .card-section {
        overflow-y: scroll;
        width: 100%;
        height: 35vh;
        min-height: 35vh;
      }
      .card-saved-search {
        overflow-y: scroll;
        width: 100%;
        height: 40vh;
        min-height: 40vh;
        .saved-filters {
          .savedContainer {
            background-color: #fff;
            overflow: hidden;
            text-overflow: ellipsis;
            border-radius: 8px;
            border: 1px solid #f0f2f4;
            cursor: pointer;
            width: 90%;
            height: 60px;
            word-break: break-word;

            &.active {
              background-color: #e8eefc;
              color: #fff;
              border: 1px solid #3D70D6 !important;
              border-radius: 8px;
            }
          }
          .C-IconToggle__container {
            color: #face1e !important;
            border-color: #c38a04 !important;
          }
          .default .C-IconToggle__container {
            color: var(--canvas-background-color--grey) !important;
            border-color: var(--canvas-background-color--grey) !important;
          }
          .C-Button {
            color: var(--canvas-background-color--grey) !important;
          }
          .C-Button.\--type-ghost:hover {
            background-color: transparent !important;
          }
        }
      }
    }
    #quick-search {
      min-height:10%;
    }
    #applied-filters {
      height:30vh;
      max-height: 30vh;
      .applied-filters-card {
        justify-content: space-between;
        flex-direction: column;
        height: 95%;
        display: flex;
        .filters-card{
          max-height: 17vh;
          overflow: scroll
        }
        .btn-holder {
          display: flex;
        }
      }
    }
  }
  .expand-collapse-btn {
    width: 100%;
    height: 30px;
    &.collapsed {
      text-align: center;
    }
    &.expanded {
      text-align: right;
    }
  }
  .search-icon {
    position: relative;
    left: 10px;
    top: -28px;
    width: 20px;
  }
  .filter-title {
    padding: 12px 12px 12px 0px;
    margin: 0 !important;
    font-weight: 400;
  }
}
.display-inline-block {
  display: inline-block;
}
.icon-position {
  top: -2px;
  margin-left: 10px;
}

.padding-10 {
  padding: 10px !important;
}
.card-applied-filter {
  overflow-y: scroll;
}


.footer-actions-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // width: 90%;
  // position: fixed;
  bottom: 10px;
  right: 10px;
}
.button-bg-hover-transparent {
  &:hover {
    background: transparent !important;
  }
}
.action-buttons-left-section {
  display: flex;
  align-items: center;
  width: 85%;
}
.pagination_main {
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  font-family: 'Helvetica For Target';
  // position: relative;
  // bottom: 2em;
}
.display_text {
  align-self: center;
  flex-basis: 250px;
}
.pagination_control {
  flex-basis: 400px !important;
  align-self: center;
}
.pagination_page_count {
  display: flex;
  flex-basis: 200px !important;
  justify-content: space-between;
  align-items: center;
  padding: 5px !important;
}

.footer-actions-section {
  .action-buttons-left-section,
  .action-buttons-right-section,
  .action-buttons-middle-section {
    .C-Button:disabled,
    .C-Button:disabled:hover,
    .C-Button[disabled] {
      background: transparent !important;
      border-color: transparent !important;
    }
    .C-Button.isLoading,
    .C-Button.isLoading:hover,
    .C-Button[aria-disabled='true'],
    .C-Button[aria-disabled='true']:hover {
      background: transparent !important;
      border-color: transparent !important;
    }
  }
  .action-buttons-middle-section {
    background: var(--canvas-font-color--interactive-contrast);
    width: 15%;
    display: flex;
    justify-content: flex-start;
    border-radius: 0 4px 4px 0;
  }
}

.delete-modal {
  .C-Modal__container {
    .C-Heading {
      background: var(--canvas-background-color--alert);
      border-top-right-radius: 15px;
      border-top-left-radius: 15px;
    }
    .C-Modal__closeButton {
      display: none;
    }
  }
}

.lp-is-fullscreen {
  height: calc(100vh - 56px);
  overflow: hidden;
  position: fixed;
  top: 58px;
  width: 100%;
  left: 0;
  z-index: 100;
  margin: 0;
  .grid-header {
    background: var(--canvas-helper-color--contrast-weak) !important;
    margin: 0 10px 0 0 !important;
  }
}
.grid-header {
  .button-container {
    button {
      &.\--icon-only {
        top: 5px;
      }
    }
  }
}
.colum-container {
  width: 320px;
  max-height: 600px;
  .tab-container {
    height: 520px;
    max-height: 520px;
    overflow-y: scroll;
  }
  .button-container {
    display: flex;
    justify-content: flex-end;
  }
}
.C-ButtonTab {
  display: flex;
  list-style: none;
  padding-left: 0;

  .C-Tabs__Item {
    .C-Tabs__Button {
      border-radius: 0;
      min-height: 100%;
      border-style: solid;
      border-width: 1px;
      background: transparent;
      border-color: var(--canvas-border-color--interactive);
      color: var(--canvas-font-color--interactive);

      &.isSelected {
        background: var(--canvas-background-color--interactive);
        color: var(--canvas-global-color--white);
        border-color: var(--canvas-border-color--interactive-contrast);
      }
    }

    &:first-child {
      .C-Tabs__Button {
        border-bottom-left-radius: var(--canvas-border-radius--dense);
        border-top-left-radius: var(--canvas-border-radius--dense);
      }

      .C-Dropdown .C-Tabs__Button {
        border-bottom-left-radius: var(--canvas-border-radius--dense);
        border-top-left-radius: var(--canvas-border-radius--dense);
      }
    }

    &:last-child {
      .C-Tabs__Button {
        border-bottom-right-radius: var(--canvas-border-radius--dense);
        border-top-right-radius: var(--canvas-border-radius--dense);
      }

      .C-Dropdown .C-Tabs__Button {
        border-bottom-right-radius: var(--canvas-border-radius--dense);
        border-top-right-radius: var(--canvas-border-radius--dense);
      }
    }

    .C-Dropdown .C-Tabs__Button {
      border-radius: 0;
    }

    &:not(:last-child) .C-Tabs__Button {
      border-right: 0;
    }
  }
  + .C-Divider {
    display: none !important;
  }
}

.error-weak-chip {
  border: 1px solid var(--canvas-border-color--error) !important;
  background: var(--canvas-background-color--error-contrast-weak) !important;
  color: var(--canvas-font-color--error-contrast) !important;
}

.success-weak-chip {
  border: 1px solid var(--canvas-border-color--success) !important;
  background: var(--canvas-background-color--success-contrast-weak) !important;
  color: var(--canvas-font-color--success-contrast) !important;
}

.input-chip {
  border: 1px solid var(--canvas-border-color--input) !important;
  background: var(--canvas-background-color--input) !important;
  color: var(--canvas-font-color--default) !important;
}

.alert-weak-chip {
  border: 1px solid var(--canvas-border-color--alert) !important;
  background: var(--canvas-background-color--alert-contrast-weak) !important;
  color: var(--canvas-font-color--alert-contrast) !important;
}

.download-infotable-btn {
  border-radius: 50% !important;
}

.info-table {
  .C-TableAdvanced {
    .C-HeaderCell__text {
      color: customStyles.$color-676B74;
    }
  }
  .C-TableAdvancedScrollContainer {
    border: none !important;
    max-height: 65vh;
    overflow-y: scroll;
  }
  .C-TableAdvanced__bodyCell {
    color: customStyles.$color-676B74;
  }
}

.info-table-content {
  display: block !important;
}

.comments-table {
  .C-TableAdvanced {
    .C-HeaderCell__text {
      color: customStyles.$color-676B74;
    }
  }

  .C-TableAdvancedScrollContainer {
    border: none !important;
  }

  .C-TableAdvanced__bodyCell {
    color: customStyles.$color-676B74;
  }
}

.comment-actions-icon {
  color: customStyles.$color-676B74 !important;
}

.dpci_filter {
  margin-bottom: 40px !important;
  max-width: 100%;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.star_icon {
  display: 'flex';
  justify-content: 'space-around';
  align-items: 'centre';
  margin: 0 !important;
  padding: 0px !important;
}
.filter-component {
  .C-IconToggle__container {
    color: #face1e !important;
    border-color: #c38a04 !important;
  }
  .default .C-IconToggle__container {
    color: var(--canvas-background-color--grey) !important;
    border-color: var(--canvas-background-color--grey) !important;
  }
}

.zero-top-padding {
  padding: 0px 0px 0px 8px !important;
}

.crossover-sidecart {
  width: 20% !important;
  .mmb-action-bar {
    display: flex;
    justify-content: flex-end;
  }
  .autocomplete-load-section {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
  .crossover-submit.C-Button:disabled,
  .crossover-submit.C-Button:disabled:hover,
  .crossover-submit.C-Button[disabled] {
    background: var(--canvas-background-color--disabled) !important;
    border-color: var(--canvas-border-color--disabled) !important;
  }
  .crossover-submit.C-Button.isLoading,
  .crossover-submit.C-Button.isLoading:hover,
  .crossover-submit.C-Button[aria-disabled='true'],
  .crossover-submit.C-Button[aria-disabled='true']:hover {
    background: var(--canvas-background-color--disabled) !important;
    border-color: var(--canvas-border-color--disabled) !important;
  }
}

.sidecart-search {
  height: 96vh;
  margin-top: 3%;
  .C-Drawer__closeButton {
    display: none;
  }
}
.search-text {
  overflow: hidden;
  max-width: 35ch;
  white-space: nowrap;
}
.right-align {
  margin-left: auto;
}
.date-filter {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  padding-bottom: 12px !important;
}
.favorite-icon-position {
  right: 3px !important;
  position: relative !important;
}
.quick-search {
  height: 80px !important;
}

.font-weight-400 {
  font-weight: 400;
}
.font-weight-100 {
  font-weight: 100;
}

.search-page-layout {
  margin: 0;
  display: grid;
  grid-template-columns: 330px 1fr; /* Left column 330px, right column takes remaining space */
  grid-template-rows: 1fr 35px; /* First row takes remaining space, second row is 30px */
  height: 90vh; /* Full viewport height */
  .top-left {
    grid-row: 1 / 2; /* First row */
    grid-column: 1 / 2; /* First column */
    .vertical-align {
      writing-mode: vertical-rl;
      transform: scale(-1);
      transform-origin: center bottom;
      min-height: 210px;
      .C-Button {
        height: auto;
      }
    }
    .vertical-align-icon {
      transform: rotate(90deg);
    }
  }
  .search-rail {
    background: var(--canvas-background-color--base);
    bottom: 0;
    display: flex;
    flex-direction: column;
    transition:
      width 0.3s ease,
      right 0.3s ease,
      left 0.3s ease;
    width: 100%;
    //height: 90vh;
    border-radius: 8px 8px 0 0;
    .section {
      flex: 1 1 auto;
      overflow: visible;
      min-height: calc(100% - 120px);
      .grid-container {
        width: 100%;
      }
    }
    .search-icon {
      position: relative;
      left: 10px;
      top: -28px;
      width: 20px;
    }
    .cancel-icon {
      position: relative;
      left: 290px;
      top: -49px;
      width: 20px;
      cursor: pointer;
    }
    .tab-card {
      width: 100%;
    }
    .filter-title {
      padding: 12px 12px 12px 0;
      margin: 0 !important;
      font-weight: 400;
    }
  }

  .bottom-left {
    grid-row: 2 / 3; /* Second row */
    grid-column: 1 / 2; /* First column */
    height: 35px; /* Fixed height */
    background: var(--canvas-background-color--base);
    border-radius: 0 0 8px 0;
  }

  .top-right {
    grid-row: 1 / 2; /* First row */
    grid-column: 2 / 3; /* Second column */
    .sticker-panel {
      .C-ExpandableSection__TopRow {
        padding-bottom: 0 !important;
        padding-top: 4px !important;
      }
      .C-ExpandableSection__Content {
        padding-top: 8px !important;
        padding-bottom: 4px !important;
      }
    }
  }
  .bottom-right {
    grid-row: 2 / 3; /* Second row */
    grid-column: 2 / 3; /* Second column */
    height: 40px; /* Fixed height */
    padding-right: 8px;
  }
  .expand-collapse-btn {
    width: 100%;
    height: 30px;
    &.collapsed {
      text-align: center;
    }
    &.expanded {
      text-align: right;
    }
  }
  &.collapsed {
    grid-template-columns: 50px 1fr; /* Left column 330px, right column takes remaining space */
  }
}


$small: 300px;
$medium: 900px;
$large: 1200px;
$zoom-height-1: 967px;
$default-height: 1100px;
$large-height: 2056px;
$action-bar-height: 72px;
.search-grid {
  width: 99%;
  height: 99vh;
  &.search-grid-min {
    height: 54vh;
    &.sticker-collapsed {
      height: 69vh;
      &.with-action {
        height: calc(69vh - 72px);
      }
    }
    &.sticker-expanded {
      height: calc(54vh - 72px);
      &.with-action {
        height: calc(54vh - 72px);
      }
      &.with-no-action {
        height: 54vh;
      }
    }
    @media screen and (min-height: $zoom-height-1) and (max-height: $default-height) {
      //background-color: #fbe0f0;
      &.sticker-collapsed {
        height: 76vh;
        &.with-action {
          height: calc(76vh - $action-bar-height);
        }
      }
      &.sticker-expanded {
        height: calc(64vh - $action-bar-height);
        &.with-action {
          height: calc(64vh - $action-bar-height);
        }
        &.with-no-action {
          height: 64vh;
        }
      }
    }
    /*** For Zoomed in (1x)  ***/
    @media screen and (min-height: $default-height) and (max-height: $large-height) {
      &.sticker-collapsed {
        height: 80vh;
        &.with-action {
          height: calc(80vh - $action-bar-height);
        }
      }
      &.sticker-expanded {
        height: calc(70vh - $action-bar-height);
        &.with-action {
          height: calc(70vh - $action-bar-height);
        }
        &.with-no-action {
          height: 70vh;
        }
      }
    }
    /*** For Zoomed out (1x)  ***/
    @media screen and (max-height: $zoom-height-1) and (min-height: $default-height)  {
      &.sticker-collapsed {
        height: 70vh;
        &.with-action {
          height: calc(70vh - $action-bar-height);
        }
      }
      &.sticker-expanded {
        height: calc(70vh - $action-bar-height);
        &.with-action {
          height: calc(60vh - $action-bar-height);
        }
        &.with-no-action {
          height: 60vh;
        }
      }
    }
  }
}

/** Show scroll bar for the grid **/
.ag-body-viewport-wrapper {
  .ag-layout-normal {
    overflow-x: scroll;
    overflow-y: scroll;
  }
}

.ag-layout-normal ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
  height: 6px;
}

.ag-layout-normal ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.remove-cursor {
  pointer-events: none;
}

.clear-all-selectiion {
  padding: 0;
}
.grid-header-info-container {
  display: flex;
  align-items: center;
  .grid-header-info-items {
    padding: 0 16px;
  }
}

.ag-checkbox-input-wrapper.ag-checked::after {
  color: var(--canvas-background-color--interactive);
}
.ag-row-selected::before {
  background-color: var(--canvas-background-color--interactive-contrast-weak-active);
}
.divider-line-margin {
  margin: 0 12px;
}

// Hide the Search Grid Checkbox column resize ber(Vertical line) 
.ag-pinned-left-header  .ag-header-row.ag-header-row-column .ag-header-cell.ag-column-first .ag-header-cell-resize {
	display: none;
}